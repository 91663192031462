import * as $ from "jquery";

export function initAutoGrowTextarea() {
  $("[data-autogrow]").each(function () {
    const $textarea = $(this);
    const minHeight = $textarea.outerHeight();

    function onChange() {
      $textarea.css("height", "auto");

      const scrollHeight = $textarea.prop("scrollHeight");
      const height = scrollHeight > minHeight ? scrollHeight : minHeight;

      $textarea.css({ height });
    }

    if ($textarea.length) {
      onChange();
      $textarea.on("input", onChange);
    }
  });
}
