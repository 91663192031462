import "@/scss/app.scss";
import $ from "jquery";
import MicroModal from "micromodal";
import { initAutoGrowTextarea } from "./modules/autogrow-textarea";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const formTypes = {
  getHelp: "getHelp",
  contact: "contact",
  orderDrugs: "order-drugs",
};

const sender_email = "m@haystov.com";
const project_id = "297185"

$(document).ready(function () {
  const mutationsObserver = new MutationObserver(([{ target }]) => {
    const opened = $(target).hasClass("is-open");

    if (!opened) {
      $applicationNumber.text("response");
      $applicationNumberParent.hide();
    }
  });

  mutationsObserver.observe($("#success").get(0), {
    attributes: true,
    attributeFilter: ["class"],
  });

  MicroModal.init();
  initAutoGrowTextarea();

  const $applicationNumberParent = $("[data-application-num-parent]");
  const $applicationNumber = $("[data-application-num]");

  $($applicationNumberParent).hide();

  $("[data-copy]").each(function () {
    const self = $(this);

    if (!window.navigator) {
      self.find("img").hide();
      return;
    }

    const { copy } = self.data();

    self.on("click", () => {
      try {
        window.navigator.clipboard.writeText(copy).then(() => {
          Toastify({
            text: "Номер картки скопійовано.",
            gravity: "bottom",
            position: "center",
          }).showToast();
        });
      } catch (err) {
        console.log(err);
      }
    });
  });

  $("form").on("submit", async function (evt) {
    evt.preventDefault();

    const self = $(this);
    const { type } = self.data();
    const button = self.find("button[type='submit']");

    const loadingNode = button.find(".loading");
    const baseNode = button.find(".base");

    if (!Object.values(formTypes).some((t) => t === type)) return;

    function startLoading() {
      loadingNode.show();
      baseNode.hide();
      button.prop("disabled", true);
    }

    function endLoading() {
      loadingNode.hide();
      baseNode.show();
      button.prop("disabled", false);
    }

    const data = self
      .serializeArray()
      .filter(({ value }) => value !== "")
      .reduce((acc, { name, value }) => {
        acc[name] = value.trim();
        return acc;
      }, {});

    let body = ``;
    let modalId;
    let title;

    if (type === formTypes.getHelp) {
      title = `${data.address}`;
      modalId = "get-help";

      if (data.situation) {
        body += `Ситуація: \n ${data.situation}\n\n`;
      }

      body += `
        Ім'я: ${data.fullname}
        \n\n Потрібно: \n ${data.need} 
        \n\n Можуть заплатити: \n ${data["can-pay"]} 
        \n\n Район: \n ${data.area}
      `.trim();

      if (data.phone) {
        body += `\n\n Телефон: \n ${data.phone}`;
      }

      if (data.address) {
        body += `\n\n Адреса: \n ${data.address}`;
      }

      if (data["senders-fullname"]) {
        body += `\n\n ПІБ Відправника: \n ${data["senders-fullname"]}`;
      }

      if (data["senders-phone"]) {
        body += `\n\n Телефон Відправника: \n ${data["senders-phone"]}`;
      }
    } else if (type === formTypes.contact) {
      if (data.contact_fullname) {
        title = `Заявка від ${data.contact_fullname}. Телефон: ${data.contact_phone}`;
        body = "";
        modalId = "contact";
      }
    } else if (type === formTypes.orderDrugs) {
      body = `\nТелефон: \n${data.phone} \n\nАдреса: \n${data.address} \n\nДозировка: \n ${data.dosage}`;
      title = `Заявка от ${data.fullname || ""} ${data.phone}`;
      modalId = null;
    } else {
      return;
    }

    startLoading();

    try {
      // 1 - Error
      // 0 / {application id} = Success
      const response = await $.ajax({
        url: "../sender.php",
        method: "post",
        data: {
          body,
          projectId: project_id,
          title,
          sender_email,
          generateId: type === formTypes.getHelp,
        },
      });

      if (response.toString() !== "1" && response.toString() !== "0") {
        $applicationNumber.text(response);
        $applicationNumberParent.show();
      }

      endLoading();
      MicroModal.close(modalId);
      MicroModal.show(response.toString() !== "1" ? "success" : "error");

      self.get(0).reset();
    } catch (err) {
      endLoading();
      MicroModal.close(modalId);
      MicroModal.show("error");
      console.log(err);
    }
  });
});
